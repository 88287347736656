/* ///////////////////////////////////////////////////////////////// */
/* ///////////////////// Global CSS Properties /////////////////////  */

a {
  color: #535252 !important;
  font-weight: normal;
}

.App {
  text-align: center;
}
/* Font Size */
.f-11 {
  font-size: 11px !important;
}
.f-10 {
  font-size: 10px !important;
}
.f-9 {
  font-size: 9px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-15 {
  font-size: 15px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-17 {
  font-size: 17px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-19 {
  font-size: 19px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-23 {
  font-size: 23px !important;
}
.f-27 {
  font-size: 27px !important;
}
.f-9 {
  font-size: 9px !important;
}
.f-29 {
  font-size: 29px !important;
}
.mb-15 {
  margin-bottom: 15 !important;
}

/* ////////////// font-weight ////////////// */

/* light */
.fw-300 {
  font-weight: 300 !important;
}
/* regular */
.fw-400 {
  font-weight: 400 !important;
}
/* medium */
.fw-500 {
  font-weight: 500 !important;
}
/* semi-bold */
.fw-600 {
  font-weight: 600 !important;
}
/* bold */
.fw-700 {
  font-weight: 700 !important;
}

/* spacing */
.s-15 {
  margin-top: 15px !important;
}
.s-16 {
  margin-top: 16px !important;
}
.s-22 {
  margin-top: 22px !important;
}
.s-27 {
  margin-top: 27px !important;
}
.s-43 {
  margin-top: 43px !important;
}
.s-58 {
  margin-top: 58px !important;
}
/* colors */
.text-main {
  color: #079751 !important;
}
.mr-102 {
  margin-right: 102px !important;
}
.mb-14 {
  margin-bottom: 14px !important;
}

/* ////////////// width ////////////// */
.w-14 {
  width: 14px !important;
}
.w-16 {
  width: 16px !important;
}
.w-18 {
  width: 18px !important;
}
.w-20 {
  width: 20px !important;
}
.w-22 {
  width: 22px !important;
}

/* ////////////// height ////////////// */
.h-14 {
  height: 14px !important;
}
.h-16 {
  height: 16px !important;
}
.h-18 {
  height: 18px !important;
}
.h-20 {
  height: 20px !important;
}
.h-22 {
  height: 22px !important;
}

/* /////////////// Pages tab /////////////// */
/* Drawer */

.pages-heading {
  margin-bottom: 22px !important;
  margin-top: 27px !important;
}
.MuiDrawer-paper {
  position: relative !important;
  left: 0 !important;
}

.route-heading {
  margin-top: 27px !important;
  margin-bottom: 0 !important;
}
/* drawer fixed */
.makeStyles-toolbar-16 {
  display: none;
}
/* ql editor */
.ql-editor {
  background-color: white;
  min-height: 70px;
}
/* .makeStyles-toolbar- {
  display: none !important;
} */
[class*="makeStyles-toolbar-"] {
  display: none !important;
}

/* select option global CSS */
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 30px !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid #dcd5d5;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid #dcd5d5;

  box-shadow: 0 0 0 2px rgb(24 144 255 / 0%);
}
.select-option-icon {
  color: black;
  font-size: 13px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 10px !important;
}
.portal-select .ant-select-selector {
  background: transparent linear-gradient(180deg, #fcfcfc 0%, #e2e2e2 100%) 0%
    0% no-repeat padding-box;
  min-width: max-content !important;
}
.portal-select .ant-select-selector .ant-select-arrow {
  right: 6px !important;
}
.app-bar-select-option .ant-select-selector {
  background: transparent linear-gradient(180deg, #fcfcfc 0%, #e2e2e2 100%) 0%
    0% no-repeat padding-box;
}
.app-bar-select-option {
  width: 86px !important;
  font-size: 12px !important;
  border: 1px solid #f3f3f3;
}
.app-bar-select-option .ant-select-selection-item {
  font-size: 12px !important;
  color: black !important;
  min-width: max-content !important;
}

/* accordion arrow */
.down-arrow-accordion {
  float: right;
  font-size: 22px !important;
  position: absolute;
  right: 15px;
  vertical-align: middle;
}

/* image action */

.image-action {
  width: 116px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #cccccc;
  text-align: center;
  color: #8a8787;
  z-index: 1;
}
.image-action-txt {
  vertical-align: middle;
  line-height: 19px;
  cursor: pointer;
}
.image-action-divider {
  height: 19px;
  border-right: 1px solid #cccccc;
}

.vertical-divider  {
  height: 19px;
  border-right: 1px solid #cccccc;
}
/* ///////////////////////////////////////////////////////////////// */
/* ///////////////////// End Global CSS Properties /////////////////////  */

/* toolbar user menu */
/* user menu dropdown */
.user-menu-container {
  min-width: 179px !important;
}
.user-menu-container .dropdown-menu {
  padding: 6px 16px !important;
  border-radius: 0;
  border: 1px solid #eeeeee;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 15px;
  font-weight: 400;
  margin-top: -3px !important;
}
.user-menu-container li:first-child {
  border-bottom: 1px solid #dfdfdf;
}
.user-menu-container li a {
  text-decoration: none;
}
.user-menu-container .dropdown-item:hover {
  background-color: transparent;
  cursor: pointer;
  color: black !important;
}
.user-menu-container button {
  background: #fcfcfc;
  outline: 0 !important;
  border: 1px solid #eeeeee;
  border-radius: 0;
}
.user-menu-container button:hover,
.user-menu-container button:focus,
.user-menu-container button:active {
  background: #fcfcfc;
  outline: 0 !important;
  border: 1px solid #eeeeee;
  border-radius: 0;
  box-shadow: none;
}
.user-action-icon {
  padding-right: 10px;
  color: #343379 !important;
}
.user-action-text {
  padding-right: 10px;
  color: black !important;
}
/* my profile */

.my-profile-input input[type="text"]:hover,
.my-profile-input input[type="text"]:focus,
.my-profile-input input[type="password"]:hover,
.my-profile-input input[type="password"]:focus {
  border-color: #0797517d !important;
  box-shadow: 0 0 0 2px #07975124 !important;
}
.my-profile-input input[type="text"] {
  border: 0;
  outline: 0;
  border: 1px solid #dcd5d5;
  height: 30px;
  padding-left: 10px;
  font-size: 12px !important;
  font-weight: 400;
  width: 212px;
  margin-bottom: 8px;
  margin-right: 8px;
}
.my-profile-input input[type="password"] {
  border: 0;
  outline: 0;
  height: 30px;
  padding-left: 10px;
  font-size: 12px !important;
  font-weight: 400;
  width: 212px;
  margin-bottom: 8px;
  margin-right: 8px;
  background: #f6f6f6;
}

.btn-mp-save-changes {
  width: 139px;
  height: 39px;
  background-color: #079751;
  text-align: center;
  vertical-align: middle;
  color: white;
  float: right;
  box-shadow: 0px 0px 6px #00000029;
  cursor: pointer;
}

.btn-mp-save-changes span {
  line-height: 39px;
}

/* end user-menu toolbar */

/* user menu -> MY Profile */

.my-profile-container {
  margin-top: 27px;
}
.my-profile-content {
  margin-top: 22px;

  background: white;
  width: 767px;
  min-height: 431px;
  box-shadow: 0px 3px 6px #75757529;
  border: 1px solid #c1c1c1;

  padding: 17px 25px 21px 25px;
}
.my-profile-img-container {
  margin-right: 52px;
}
.my-profile-input-container {
  margin-right: 33px;
}
.my-profile-img {
  width: 108px;
  height: 108px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 50%;
}
.my-profile-input button {
  border: 1px solid #079751;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 0;
  width: 163px;
  height: 39px;
  color: #079751;

  margin-top: 33px;
}
.my-profile-input button:hover,
.my-profile-input button:focus,
.my-profile-input button:active {
  color: #079751;
  box-shadow: none;
}
.mp-input-heading {
  padding-bottom: 7px !important;
}
.mt-18 {
  padding-top: 18px !important;
}
.flex-wrapper {
  margin-top: 18px;
  margin-bottom: 50px;
}
/* upload image */
.mp-upload-img {
  color: #079751;
  margin-top: 10px;
}
.mp-upload-img button {
  border: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
  color: #079751 !important;
  padding: 0;
  padding-left: 10px;
}
.mp-upload-img button:hover,
.mp-upload-img button:active,
.mp-upload-img button:focus {
  border: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
}

/* user information */

.user-info-container {
  margin-top: 27px;
}
.user-info-content {
  margin-top: 22px;
  background: white;
  width: 767px;
  min-height: 431px;
  box-shadow: 0px 3px 6px #75757529;
  border: 1px solid #c1c1c1;

  padding: 17px 25px 21px 25px;
}

.user-info-input input[type="text"] {
  width: 177px !important;
  margin-right: 28px !important;
}
.user-info-input input[type="password"] {
  width: 177px !important;
  margin-right: 28px !important;
}

.user-info-collage-select {
  width: 382px !important;
  margin-right: 28px !important;
}
.user-info-lang-select {
  width: 103px !important;
}
.user-info-btn-forget-pass button {
  box-shadow: 0px 0px 6px #dbdbdb29;
  border: 1px solid #079751;
  border-radius: 0;
  width: 163px;
  height: 39px;
  color: #079751;
  margin-top: 33px;
}
.user-info-btn-forget-pass button:hover,
.user-info-btn-forget-pass button:focus,
.user-info-btn-forget-pass button:active {
  color: #079751;
  box-shadow: 0px 0px 6px #dbdbdb29;
}
.user-info-save-changes {
  line-height: 39px;
  width: 139px;
  height: 39px;
  background-color: #079751;
  text-align: center;
  vertical-align: middle;
  color: white;
  float: right;
  box-shadow: 0px 0px 6px #00000029;
  cursor: pointer;
  margin-top: 82px;
}
/* ////////////// pages -> Home ////////////// */

.pages-container {
  width: 96.5%;
  padding-left: 20px;
}

.content-wrapper {
  /* padding: 20px 25px 20px 20px; */

  font-size: 14px !important;
}
.bg-content-container {
  width: 100%;
  background-color: #fcfcfc;
  padding: 20px 25px 20px 20px;
  border: 1px solid #e6e4e4;
  margin-bottom: 15px;
}
.h-heading {
  margin-bottom: 17px !important;
}
/* h pagination */
.h-pagination {
  width: 28px;
  height: 28px;
  text-align: center;
  display: inline-block;
  margin-right: 7px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
.h-pagination-active {
  color: #343379;
  border: 1px solid #343379;
  background-color: #ffffff;
}
.h-pagination-inActive {
  color: #b7b7b7;
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
}
.h-pagination p {
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
}
/* h upload image */
.header-upload-image {
  width: 100% !important;
  height: 183px !important;
  background-color: #fcfeff;
  border: 1px solid #e0ebe6;
  margin-top: 10px;
  position: relative;
}
.header-upload-image-container span {
  display: inline-block;
  color: #888888;
  margin-top: 7px;
}

/* //////////////////////////image */

/* image */
.h-image-upload-container {
  width: 100%;
  height: 183px;

  margin-bottom: 35px;
  margin-top: 10px;
}

.h-image-upload {
  position: relative;
  width: 100%;
  height: 183px;
  background-color: #fcfeff;
  border: 1px solid #e0ebe6;
  position: relative;
  margin: 0;
}
.catalogue-wide-image :is(.h-image-upload, .h-image-upload-container) {
  height: 95px !important;
}
.h-image-upload:hover ~ .image-action-container {
  display: block;
}

.h-image-upload:hover .image-action-container {
  background-color: #079751;
}
.image-action {
  background-color: white;
}

/* //////////////////////////image */
/* header upload button */

.header-upload-image-button {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: fit-content !important;
  border: 2px dashed #e6fbf2;
  padding: 10px 50px;
  cursor: pointer;
}
.header-upload-image-button span {
  color: #079751;
  vertical-align: middle;
  font-size: 12px !important;
}
.header-upload-image-button .img {
  color: #c4e6d6;
  vertical-align: middle;
}
.carousel-text-heading {
  margin-top: 16px;
}
.carousel-text-area {
  width: 100% !important;
  height: 106px !important;
  background-color: #ffffff;
  /* border: 1px solid #dcd5d5; */
  /* box-shadow: 0px -3px 3px #e5e5e529; */
  border-collapse: collapse;
}
.carousel-text-area-heading {
  height: 35px !important;
  background-color: #fcfcfc;
  border-collapse: collapse;
  border-bottom: 1px solid #dcd5d5 !important;
}

/* text area */

.text-area {
  width: 100%;
  resize: vertical;
}

.h-accordion-container {
  margin-bottom: 16px;
}

.accordion-detail input[type="text"] {
  border: 0;
  outline: 0;
  border: 1px solid #dcd5d5;
  height: 30px;
  padding-left: 10px;
  font-size: 12px !important;
  font-weight: 400;
  width: 380px;
  margin-bottom: 8px;
  margin-right: 8px;
}
.accordion-detail input[type="text"]:focus {
  border: 0;
  border: 1px solid #dcd5d5;
  outline: none !important;
  box-shadow: none;
}

.accordion-detail .upload-file {
  display: inline-block;
  border: 1px solid #079751;
  padding: 5px 12px;
  border-radius: 0;
  color: #079751 !important;
}

.h-add-another {
  cursor: pointer;
}
.btn-save-changes {
  width: 139px;
  height: 39px;
  background-color: #079751;
  text-align: center;
  vertical-align: middle;
  color: white;
  float: right;
  box-shadow: 0px 0px 6px #00000029;
  margin-top: 35px;
  cursor: pointer !important;
}
.btn-save {
  width: 139px;
  height: 39px;
  background-color: #079751;
  text-align: center;
  vertical-align: middle;
  color: white;
  float: right;
  box-shadow: 0px 0px 6px #00000029;
  margin-top: 35px;
  cursor: pointer !important;
}
/* .btn-save-changes span {
  line-height: 39px;
} */

/* ////////////////// about us ////////////////// */

/* kiu vision */

.a-detail input[type="number"] {
  border: 0;
  outline: 0;
  border: 1px solid #dcd5d5;
  height: 30px;
  padding-left: 18px;
  font-size: 12px !important;
  font-weight: 400;
  width: 66px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.a-detail input[type="number"]:focus {
  border: 0;
  border: 1px solid #dcd5d5;
  outline: none !important;
  box-shadow: none;
}

.future-image-upload {
  width: 172px;
  height: 172px;
  background-color: #fcfeff;
  border: 1px solid #e6e4e4;
  position: relative;
  margin: 0;
}

.medium-img-text {
  color: #079751;
  vertical-align: middle;
  font-size: 12px !important;
}
.medium-img {
  color: #c4e6d6;
  vertical-align: middle;
}
.img-size-200 {
  color: #bcbcbc;
}

.future-image-upload-container {
  width: 172px;
  height: 172px;

  margin-bottom: 35px;
}
.f-img-size {
  color: #888888;
  margin-top: 16px;
}

.vision-container .bg-content-container {
  width: 100%;
  background-color: white !important;
  border: 0 !important;
}

/* director */

.d-input-name input[type="text"] {
  width: 310px !important;
  margin-right: 0 !important;
}

.input-heading {
  margin-top: 16px !important;
  padding-bottom: 7px !important;
}

/* board of trustees */
.b-container {
  position: relative;
}
.icon-container {
  position: absolute;
  top: 0;
  right: 0;
}
.b-container .b-icon-close {
  width: 18px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.b-container .b-icon-edit {
  width: 14px;
  cursor: pointer;
}

.b-container-input input[type="text"] {
  width: 340px;
  margin-right: 8px;
  margin-bottom: 0 !important;
}
.b-input-heading {
  margin-top: 16px;
  margin-bottom: 7px;
}

.future-image-upload-container {
  margin-bottom: 35px;
  width: 143px;
  margin-top: 19px;
}

.f-img-size {
  margin-top: 7px;
}
.b-add-another {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

/* //////////////////// Faculties ////////////////////  */

.faculty-container {
  position: relative;
}
.faculty-heading {
  margin-bottom: 7px;
}

.faculty-container .b-icon-close {
  width: 18px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.faculty-container .b-icon-edit {
  width: 14px;
  cursor: pointer;
}

.faculty-container .future-image-upload-container {
  margin-bottom: 35px;
  /* width: 143px; */
  margin-bottom: 0;
  margin-top: 6px;
}

.faculty-container .future-image-upload {
  width: 155px;
  height: 111px;
  margin-bottom: 0;
}

.faculty-container .future-image-upload .ant-upload-select-picture-card {
  width: 143px;
  height: 100px;
  margin-bottom: 0;
}
.faculty-container .future-image-upload-container {
  margin-bottom: 0px;
  height: 171px;
}

.faculty-add-another {
  position: absolute;
  left: 50%;
  bottom: -40px;
  transform: translateX(-50%);
  cursor: pointer;
}

/* college and institute */

.college-text-area textarea {
  width: 551px !important;

  outline: 0;
  border: 0;
  min-height: 75px;
  border: 1px solid #dcd5d5;
  resize: vertical;
  font-size: 12px !important;
  font-weight: 400;
}

.college-container input[type="text"] {
  width: 551px !important;
}

/* news and events */

/* tabs */
.news-and-events .ant-tabs-nav {
  margin-bottom: 0;
  width: fit-content;
}
.news-and-events {
  margin-top: -53px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border: 1px solid #343379 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #343379 !important;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #343379 !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 4px 15px;
}
.ant-tabs-tab {
  font-size: 15px !important;
  color: #a2a2a2;
  font-weight: 500;
}
.ant-tabs-tab:hover {
  color: #a2a2a2;
}

/* end tabs */

/* news */

.news-add-another {
  cursor: pointer;
  margin-bottom: 14px;
}

.news-content {
  margin-top: 90px;
}

.news-content input[type="text"] {
  width: 100% !important;
}

.news-content textarea {
  width: 100% !important;
  min-height: 86px;
}

/* image */
.news-image-upload-container {
  height: 152px;
  width: 100%;
  margin-bottom: 35px;
  margin-top: 15px;
}

.news-image-upload {
  height: 152px;
  width: 100%;
  background-color: #fcfeff;
  border: 1px solid #e0ebe6;
  position: relative;
  margin: 0;
}
.news-image-upload .ant-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fcfeff;
  width: 100%;
}
.news-image-upload .ant-upload-select-picture-card {
  border: 0;
  border: 2px dashed #c4e6d6;
  height: 136px;
  width: 489px;
  margin: 0;
}
.news-image-upload .ant-upload-select-picture-card:hover {
  border: 2px dashed #c4e6d6;
}

/* events */
.events .p-date {
  padding-right: 30px !important;
}
.dateTime-container {
  margin-bottom: 8px;
}
.events-date-time {
  margin-bottom: 8px;
}

.events-date-time .ant-picker:hover,
.ant-picker-focused {
  border: 1px solid #dcd5d5;
}
.events-date-time .ant-picker-focused {
  box-shadow: 0 0 0 2px rgb(24 144 255 / 0%);
}
.ant-picker-today-btn,
.ant-picker-today-btn:hover {
  color: #079751;
  text-decoration: none;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #079751;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #079751;
}
.ant-picker-header-view button:hover {
  color: #079751;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary {
  background-color: #079751;
  border: #079751 1px solid;
}
.ant-picker-now-btn,
.ant-picker-now-btn:hover {
  text-decoration: none;
  color: #079751;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #c4e6d6;
}

/* contact us */

.contact-us-content input[type="text"] {
  width: 211px;
}
.contact-us-heading {
  width: 60px;
}

.contact-main-heading {
  margin-bottom: 20px !important;
}

/* ////////// online degree modal */

.degree-actions-container {
  margin-top: -53px;
}

.online-degree-modal input[type="text"] {
  width: 214px;
}
.degree-main-heading {
  margin-bottom: 20px !important;
  margin-right: 16px !important;
}
.degree-main-edit {
  color: #079751;
  cursor: pointer;
}
.degree-main-delete {
  cursor: pointer;
  color: #df4747;
}
.degree-main-divider {
  height: 17px;
  border-right: 1px solid #cccccc;
  margin-left: 9px;
  margin-right: 7px;
}
/* online degree delete modal  */
.online-degree-delete-modal .ant-modal-title {
  color: red !important;
  text-align: center;
}
.online-degree-delete-modal .ant-modal-header {
  border: 0 !important;
}
.online-degree-delete-modal .ant-modal-footer {
  border: 0 !important;
  text-align: center;
}
.online-degree-delete-modal .ant-modal-footer .ant-btn {
  width: 134px;
  height: 39px;
  background-color: #f3f3f3;
  color: #7b7b7b;
  border: 0 !important;
  box-shadow: 0px 0px 6px #36363629;
  font-size: 16px;
  font-weight: 500;
}

.online-degree-delete-modal
  :is(.ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn-primary) {
  background-color: #e20c29 !important;
  color: white !important;
}

/* online degree -> syllabus */
.semester-add-another {
  margin-top: 32px;
}

.semester-name input[type="text"] {
  width: 371px !important;
  margin-right: 37px;
}
.semester-hours input[type="text"] {
  width: 77px !important;
}
.semester-delete-icon {
  margin-left: 12px;
}

.add-new-course {
  margin-top: 8px !important;
  cursor: pointer;
}

.btn-save-section {
  width: 117px;
  height: 33px;
  border: 1px solid #079751;
  text-align: center;
  vertical-align: middle;
  color: #079751;
  box-shadow: 0px 0px 6px #00000029;
  cursor: pointer;
  line-height: 33px;
}
.btn-save-section-no-Line {
  width: 117px;
  height: 33px;
  border: 1px solid #079751;
  text-align: center;
  vertical-align: middle;
  color: #079751;
  box-shadow: 0px 0px 6px #00000029;
  cursor: pointer;
}

/* /////////////////////// courses ///////////////////////  */

/* ///////////////// how it works  ///////////////// */

/*  assessment  */
.assessment-details {
  /* background-color: black; */
  width: 75%;
}
.assessment-details input[type="number"] {
  width: 50px;
  margin-right: 0px;
  margin-bottom: 0px;
  border: 0;
  height: 30px;
  outline: 0;
}
.assessment-input {
  width: 70px !important;
  height: 30px !important;
}
.assessment-input:hover,
.assessment-input:focus {
  border: 0 !important;
  border: 1px solid #dcd5d5 !important;
  box-shadow: none;
}
.assessment-content-heading {
  margin-right: 40px !important;
  width: 100px;
}
.assessment-details {
  margin-bottom: 16px;
}

.bg-semester-subjects {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
  padding: 6px 25px 6px 20px;
}
.bg-semester-details {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
  padding: 11px 25px 11px 20px;
}

.subject-course-input input[type="text"] {
  width: 183px !important;
  margin-bottom: 0 !important;
}

.subject-input input[type="text"] {
  margin-bottom: 0 !important;
  width: 222px !important;
}
.subject-credit-input input[type="text"] {
  margin-bottom: 0 !important;
  width: 65px !important;
}

.add-another-subject {
  cursor: pointer;
}

/* courses -> course overview */

.course-include-value input[type="text"] {
  width: 214px !important;
}

/* courses -> course categories */
.category-image-upload-container {
  height: fit-content !important;
}
.category-image-upload-container .h-image-upload {
  height: 95px !important;
}

.category-image-upload-container
  .h-image-upload
  .ant-upload-select-picture-card {
  width: 525px;
  height: 83px;
}

.course-catalogue-input input[type="text"] {
  width: 246px !important;
  margin-right: 160px;
}
.course-catalogue-credit-hours-input input[type="text"] {
  width: 103px !important;
}

/* courses -> online free courses */

.free-courses-name input[type="text"] {
  width: 270px !important;
  margin-right: 25px !important;
}

.free-courses-lecturer input[type="text"] {
  width: 343px !important;
}
.free-courses-access input[type="text"] {
  width: 638px !important;
}

/* //////////////////// KIU Admission //////////////////// */

.ep-email-input input[type="text"] {
  width: 224px !important;
}
.ep-email-fax input[type="text"] {
  width: 224px !important;
}
.ep-post-address-input input[type="text"] {
  width: 436px !important;
}
.ep-po-input input[type="text"] {
  width: 224px !important;
}

/* admission deadline */

.ad-mr {
  margin-right: 10px;
  margin-bottom: 17px !important;
}
.ad-date-m {
  margin-top: 13px !important;
  margin-bottom: 10px !important;
}
.date-mr {
  margin-right: 60px !important;
}

/* select option */
.ad-select-option {
  width: 83px !important;
}

/* /////////////// Why KIU /////////////// */

.save-section {
  margin-top: 30px;
}
.ant-typography {
  margin-bottom: 3px !important;
}

/* .makeStyles-content-17 {
  margin-top: 100px;
} */

/* //////////////////////// login //////////////////////// */

.login-wrapper {
  width: 70% !important;
  height: 70% !important;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-container {
  width: 486px !important;
  min-height: 439px !important;
  background-color: white;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #dbdbdb;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 14px 118px 44px 118px;
}
.login-container img {
  width: 242px;
  margin-bottom: 16px;
}
.login-heading {
  color: #079751;
}
.login-h {
  margin-top: 30px;
}
.login-container .ant-input {
  width: 252px !important;
}
.login-container .ant-input-password {
  width: 252px !important;
}
.login-lang-text {
  color: #6e6e6e !important;
}
.login-forget-text {
  color: #0f78be;
  margin-top: 8px;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.btn-login {
  width: 252px !important;
  height: 39px !important;
  background-color: #079751;
  color: white;
  box-shadow: none;
  border: none;
  margin-top: 25px;
}
.login-lang {
  margin-bottom: 23px !important;
  margin-top: 5px !important;
}
.cursor-pointer {
  cursor: pointer;
}
/* Input border and box shadow  */
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-select-focused .ant-select-selector,
.ant-select:hover .ant-select-selector {
  border-color: #0797517d !important;
  box-shadow: 0 0 0 2px #07975124 !important;
}
.rtl input {
  padding-right: 8px !important;
  margin-left: 8px !important;
  margin-right: 0px !important;
}
.rtl .icon-container {
  right: 8px;
}
.makeStyles-content-17 {
  max-width: 100% !important;
}
.rtl .user-info-btn-forget-pass {
  margin-right: 8px;
}
.rtl .select-option-rtl {
  margin: 8px;
}
.rtl .events .p-date {
  padding-right: 0 !important;
  padding-left: 30px !important;
}
.rtl .date-mr {
  margin-right: 0 !important;
  margin-left: 60px !important;
}

.admission-deadline .ant-select-selector {
  margin-right: 8px !important;
}

.rtl .ql-editor {
  direction: rtl;
  text-align: right;
}

.header-img-uploaded .ant-upload {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}

.ant-upload-list {
  display: inline-block !important;
}

.MuiAccordionDetails-root {
  display: block !important;
}

/* /////////////////////////////////////////////////////////////////// */
/*                        header image box                             */
/* /////////////////////////////////////////////////////////////////// */

.image-box {
  width: 291px !important;
  height: 77px !important;
  border: 2px dashed #c4e6d6;
  color: #079751;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.h-uploaded .image-box {
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
}
.h-uploaded .image-box img {
  width: 100% !important;
  height: 100% !important;
}
.upload-icon-img {
  color: #c4e6d6 !important;
}
.catalogue-wide-image .image-box {
  width: 525px !important;
  height: 83px !important;
}
.catalogue-wide-image .h-uploaded .image-box {
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
}

/* ////////////////////// */

.uploaded :is(.ant-upload, img) {
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
  box-shadow: none !important;
}

/* /////////////////////////////////////////////////////////////////// */
/*                        section edit action btn                      */
/* /////////////////////////////////////////////////////////////////// */

.edit-action .cancel {
  color: #9c9c9c !important;
}
.edit-action .save .ant-btn {
  color: #079751 !important;
  border: 0 !important;
  background: none !important;
  box-shadow: none !important;
}
.mr-19 {
  margin-right: 19px !important;
}
.mb-9 {
  margin-bottom: 9px !important;
}

.input-disable :is(input[type="text"], input[type="password"]) {
  background: #eeeeee !important;
  border: 0 !important;
}
/* /////////////////////////////////////////////////////////////////// */
/*                        Vision image box                             */
/* /////////////////////////////////////////////////////////////////// */

.vision-image-box {
  width: 151px !important;
  height: 149px !important;
  border: 2px dashed #c4e6d6;
  color: #079751;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.vision-image-box img {
  width: 55px !important;
}
.uploaded .vision-image-box {
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
}
.uploaded .vision-image-box img {
  width: 100% !important;
  height: 100% !important;
}
/* /////////////////////////////////////////////////////////////////// */
/*                        Bot                                          */
/* /////////////////////////////////////////////////////////////////// */
.bot-image-box {
  width: 143px !important;
  height: 177px !important;
  border: 2px dashed #c4e6d6;
  color: #079751;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.bot-image-box img {
  width: 40px !important;
}
.uploaded .bot-image-box {
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
}
.uploaded .bot-image-box img {
  width: 100% !important;
  height: 100% !important;
}

.bot-image .future-image-upload {
  width: 155px !important;
  height: 191px !important;
}

/* /////////////////////////////////////////////////////////////////// */
/*                        Faculty image box                            */
/* /////////////////////////////////////////////////////////////////// */
.faculty-image-box {
  width: 143px !important;
  height: 100px !important;
  border: 2px dashed #c4e6d6;
  color: #079751;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.faculty-image-box img {
  width: 40px !important;
}
.uploaded .faculty-image-box {
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
}
.uploaded .faculty-image-box img {
  width: 100% !important;
  height: 100% !important;
}

.faculty-image .future-image-upload {
  width: 155px !important;
  height: 111px !important;
}
.news-image-upload

/* /////////////////////////////////////////////////////////////////// */
/*                        news and events image box                    */
/* /////////////////////////////////////////////////////////////////// */
.news-image-box {
  width: 489px !important;
  height: 136px !important;
  border: 2px dashed #c4e6d6;
  color: #079751;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.news-image-box img {
  width: 40px !important;
}
.uploaded .news-image-box {
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
}
.uploaded .news-image-box img {
  width: 100% !important;
  height: 100% !important;
}

.news-image .news-image-upload {
  width: 100% !important;
  height: 152px !important;
}

/* /////////////////////////////////////////////////////////////////// */
/*                        course catalogues image box                  */
/* /////////////////////////////////////////////////////////////////// */
.course-catalogue-image-box {
  width: 489px !important;
  height: 136px !important;
  border: 2px dashed #c4e6d6;
  color: #079751;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.course-catalogue-image-box img {
  width: 40px !important;
}
.uploaded .course-catalogue-image-box {
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
}
.uploaded .course-catalogue-image-box img {
  width: 100% !important;
  height: 100% !important;
}

.course-catalogue-image .course-catalogue-image-upload {
  width: 100% !important;
  height: 152px !important;
}

/* /////////////////////////////////////////////////////////////////// */
/*                        courses -> fee structure                     */
/* /////////////////////////////////////////////////////////////////// */
.fee-structure .ant-form-item {
  margin-bottom: 0 !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.error {
  display: inline-block;
  width: 200px;
  text-align: center;
  position: absolute;
  top: 80px;
  left: 50%;
  z-index: 1;
}

.ant-card-body {
  padding: 0 !important;
}
.images-background-config {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 100%;
}

.ant-input-number {
  margin-bottom: 8px !important;
  height: 30px !important;
  border: 1px solid #dcd5d5 !important;
}

.subjects-study-overview .accordion-detail .i-number {
  border: 0 !important;
}
.select-semester {
  width: 50px !important;
}

/* ///////////////////////// laoding */
.loading-pages {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-message .anticon {
  top: -3px !important;
}
.page-icon-white svg path {
  fill: white !important;
}

.wide-image-preview {
  width: 100%;
  height: 93px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.study-overview-action-container .icon-container {
  top: 25px !important;
  right: 20px !important;
}
.study-overview-action-container .edit-action {
  right: -9px !important;
  top: 21px !important;
}

.quill-readonly {
  opacity: 0.7;
}
.quill-readonly:hover {
  cursor: no-drop;
}
.degree-edit-modal .ant-modal-content {
  min-height: 370px !important;
}
.degree-edit-modal .accordion-detail input[type="text"] {
  margin-right: 0 !important;
}
.degree-edit-modal .btn-save-changes {
  margin-top: 15px !important;
}
.btn-cancel-degree-edit {
  color: #7b7b7b !important;
  background: #f3f3f3 !important;
  border: 0 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-right: 20px;
  width: 134px !important;
  height: 39px !important;
  box-shadow: none !important;
}

.btn-cancel-degree-save {
  width: 134px !important;
  height: 39px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.disabled-text {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.bg-content-container-testimonial {
  width: 100%;
  background-color: #fcfcfc;
  padding: 30px 25px 16px 20px;
  border: 1px solid #e6e4e4;
  margin-bottom: 20px;
}

.bg-content-container-testimonial .bot-image .future-image-upload {
  width: 140px !important;
  height: 130px !important;
  border-radius: 50%;
  margin-top: 20px;
}

.bg-content-container-testimonial .bot-image-box {
  width: 130px !important;
  height: 120px !important;
  border-radius: 50%;
}

.bg-content-container-testimonial .bot-image-box img {
  border-radius: 50%;
}

.bg-content-container-testimonial .bot-image-box .upload-img {
  width: 30px !important;
}

.bg-content-container-testimonial .uploaded .bot-image-box {
  width: 140px !important;
  height: 130px !important;
}
